<template>
     <div class="card">
        <div class="header">
            <h2>Last comments</h2>
        </div>
        <div class="body">                           
            <ul class="right_chat list-unstyled">
                <li class="online">
                    <a href="javascript:void(0);">
                        <div class="media">
                            <img class="media-object " src="./../../assets/xs/avatar4.jpg" alt="">
                            <div class="media-body">
                                <span class="name">Chris Fox <small class="float-right">Just now</small></span>
                                <span class="message">Lorem ipsum Veniam aliquip culpa laboris minim tempor</span>
                                <span class="badge badge-outline status"></span>
                            </div>
                        </div>
                    </a>                            
                </li>
                <li class="online">
                    <a href="javascript:void(0);">
                        <div class="media">
                            <img class="media-object " src="./../../assets/xs/avatar5.jpg" alt="">
                            <div class="media-body">
                                <span class="name">Joge Lucky <small class="float-right">38 minutes ago</small></span>
                                <span class="message">Many desktop publishing packages and web page editors</span>
                                <span class="badge badge-outline status"></span>
                            </div>
                        </div>
                    </a>                            
                </li>
                <li class="offline">
                    <a href="javascript:void(0);">
                        <div class="media">
                            <img class="media-object " src="./../../assets/xs/avatar2.jpg" alt="">
                            <div class="media-body">
                                <span class="name">Isabella <small class="float-right">2 hours ago</small></span>
                                <span class="message">Contrary to popular belief, Lorem Ipsum is not simply random text</span>
                                <span class="badge badge-outline status"></span>
                            </div>
                        </div>
                    </a>                            
                </li>
                <li class="offline">
                    <a href="javascript:void(0);">
                        <div class="media">
                            <img class="media-object " src="./../../assets/xs/avatar1.jpg" alt="">
                            <div class="media-body">
                                <span class="name">Folisise Chosielie <small class="float-right">32 hours ago</small></span>
                                <span class="message">There are many variations of passages of Lorem Ipsum available, but the majority</span>
                                <span class="badge badge-outline status"></span>
                            </div>
                        </div>
                    </a>                            
                </li>
                <li class="online">
                    <a href="javascript:void(0);">
                        <div class="media">
                            <img class="media-object " src="./../../assets/xs/avatar3.jpg" alt="">
                            <div class="media-body">
                                <span class="name">Alexander <small class="float-right">1 day ago</small></span>
                                <span class="message">It is a long established fact that a reader will be distracted</span>
                                <span class="badge badge-outline status"></span>
                            </div>
                        </div>
                    </a>                            
                </li>                        
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Comments',
}
</script>
<style scoped>
</style>