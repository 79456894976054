<template>
    <div class="card overflowhidden">
        <div class="body top_counter bg-success">
            <div class="icon bg-transparent">
                <img src="../../assets/xs/avatar2.jpg" class="rounded-circle" alt="">
            </div>
            <div class="content text-light">
                <div>Team Leader</div>
                <h6>Maryam Amiri</h6>
            </div>
        </div>
        <div class="body">
            <div class="list-group list-widget">
                <a href="javascript:void(0);" class="list-group-item">
                    <span class="badge badge-success">654</span>
                    <i class="fa fa-envelope text-muted"></i>Inbox</a>
                <a href="javascript:void(0);" class="list-group-item">
                    <span class="badge badge-info">364</span>
                    <i class="fa fa-eye text-muted"></i> Profile visits</a>
                <a href="javascript:void(0);" class="list-group-item">
                    <span class="badge badge-warning">19</span>
                    <i class="fa fa-bookmark text-muted"></i> Bookmarks</a>
                <a href="javascript:void(0);" class="list-group-item">
                    <span class="badge badge-warning">12</span>
                    <i class="fa fa-phone text-muted"></i> Call</a>
                <a href="javascript:void(0);" class="list-group-item">
                    <span class="badge badge-danger">54</span>
                    <i class="fa fa-comments-o text-muted"></i> Messages</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'TeamLeader'
}
</script>
<style scoped>

</style>