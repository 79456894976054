<template>
      <div class="card">
        <div class="header">
            <h2>Resent Chat</h2>
           <card-actions></card-actions>
        </div>
        <div class="body text-center">
            <div class="cwidget-scroll">
                <ul class="chat-widget m-r-5 clearfix">
                    <li class="left float-left">
                        <img src="./../../assets/xs/avatar2.jpg" class="rounded-circle" alt="">
                        <div class="chat-info">       
                            <span class="message">Hello, John<br>What is the update on Project X?</span>
                        </div>
                    </li>
                    <li class="right">
                        <img src="./../../assets/xs/avatar1.jpg" class="rounded-circle" alt="">
                        <div class="chat-info">
                            <span class="message">Hi, Alizee<br> It is almost completed. I will send you an email later today.</span>
                        </div>
                    </li>
                    <li class="left float-left">
                        <img src="./../../assets/xs/avatar2.jpg" class="rounded-circle" alt="">
                        <div class="chat-info">
                            <span class="message">That's great. Will catch you in evening.</span>
                        </div>
                    </li>
                    <li class="right">
                        <img src="./../../assets/xs/avatar1.jpg" class="rounded-circle" alt="">
                        <div class="chat-info">
                            <span class="message">Sure we'will have a blast today.</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="input-group p-t-15">
                <div class="input-group-prepend">
                    <span class="input-group-text" ><i class="icon-paper-plane"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Enter text here...">                                    
            </div>                            
        </div>
    </div>
</template>
<script>
import CardActions from '@/components/core/CardActions.vue'
export default {
    name: 'AnaliticalChat',
     components: {
        'card-actions': CardActions,
    },
}
</script>
<style scoped>
</style>