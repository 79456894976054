<template>
    <div id="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-3 cool-md-6 col-sm-12">
                    <div class="card">
                        <ul class="pricing body">
                            <li><big>Start</big></li>
                            <li>Responsive Design</li>
                            <li>Color Customization</li>
                            <li>HTML5 &amp; CSS3</li>
                            <li>Styled elements</li>
                            <li>
                                <h3>$199</h3>
                                <span>per month</span>
                            </li>
                            <li><button class="btn btn-outline-secondary">Join Now</button></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 cool-md-6 col-sm-12">
                    <div class="card">
                        <ul class="pricing body active">
                            <li><big>Good</big></li>
                            <li>Responsive Design</li>
                            <li>Color Customization</li>
                            <li>HTML5 &amp; CSS3</li>
                            <li>Styled elements</li>
                            <li>
                                <h3>$299</h3>
                                <span>per month</span>
                            </li>
                            <li><button class="btn btn-primary ">Join Now</button></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 cool-md-6 col-sm-12">
                    <div class="card">
                        <ul class="pricing body">
                            <li><big>Ultima</big></li>
                            <li>Responsive Design</li>
                            <li>Color Customization</li>
                            <li>HTML5 &amp; CSS3</li>
                            <li>Styled elements</li>
                            <li>
                                <h3>$399</h3>
                                <span>per month</span>
                            </li>
                            <li><button class="btn btn-outline-secondary">Join Now</button></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 cool-md-6 col-sm-12">
                    <div class="card">
                        <ul class="pricing body">
                            <li><big>Vip</big></li>
                            <li>Responsive Design</li>
                            <li>Color Customization</li>
                            <li>HTML5 &amp; CSS3</li>
                            <li>Styled elements</li>
                            <li>
                                <h3>$799</h3>
                                <span>per month</span>
                            </li>
                            <li><button class="btn btn-outline-secondary">Join Now</button></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row clearfix">
                <div class="col-12">
                    <h5>Pricing Table Option 2</h5>
                    <hr>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="card pricing2">
                        <div class="body">
                            <div class="pricing-plan">
                                <img src="../../../assets/paper-plane.png" alt="" class="pricing-img">
                                <h2 class="pricing-header">Start</h2>
                                <ul class="pricing-features">
                                    <li>Responsive Design</li>
                                    <li>Color Customization</li>
                                    <li>HTML5 &amp; CSS3</li>
                                    <li>Styled elements</li>
                                </ul>
                                <span class="pricing-price">Free</span>
                                <a href="javascript:void(0);" class="btn btn-outline-primary">Sign up</a>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="card pricing2">
                        <div class="body">
                            <div class="pricing-plan">
                                <img src="../../../assets/plane.png" alt="" class="pricing-img">
                                <h2 class="pricing-header">Good</h2>
                                <ul class="pricing-features">
                                    <li>Responsive Design</li>
                                    <li>Color Customization</li>
                                    <li>HTML5 &amp; CSS3</li>
                                    <li>Styled elements</li>
                                </ul>
                                <span class="pricing-price">$149</span>
                                <a href="javascript:void(0);" class="btn btn-primary">Join Now</a>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="card pricing2">
                        <div class="body">
                            <div class="pricing-plan">
                                <img src="../../../assets/space-ship.png" alt="" class="pricing-img">
                                <h2 class="pricing-header">Ultima</h2>
                                <ul class="pricing-features">
                                    <li>Responsive Design</li>
                                    <li>Color Customization</li>
                                    <li>HTML5 &amp; CSS3</li>
                                    <li>Styled elements</li>
                                </ul>
                                <span class="pricing-price">$349</span>
                                <a href="javascript:void(0);" class="btn btn-outline-primary">Join Now</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row clearfix">
                <div class="col-12">
                    <h5>Pricing Table Option 3</h5>
                    <hr>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="card pricing3">
                        <div class="body">
                            <div class="pricing-option">
                                <i class="icon-screen-desktop"></i>
                                <h5>Ui design</h5>
                                <hr>
                                <div class="m-t-30 m-b-30">
                                    <h6>Files</h6>
                                    <p>.PSD .AI .PNG .SVG<br>Licensed for 1 project</p>
                                </div>
                                <div class="price">
                                    <span class="price">149 <b>$</b></span>
                                </div>
                                <a href="javascript:void(0);" class="btn btn-outline-secondary">Purchase now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="card pricing3">
                        <div class="body">
                            <div class="pricing-option">
                                <i class="icon-user"></i>
                                <h5>Ux design</h5>
                                <hr>
                                <div class="m-t-30 m-b-30">
                                    <h6>Files</h6>
                                    <p>.PSD .AI .PNG .SVG<br>Licensed for 5 project</p>
                                </div>
                                <div class="price">
                                    <span class="price">249 <b>$</b></span>
                                </div>
                                <a href="javascript:void(0);" class="btn btn-outline-secondary">Purchase now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="card pricing3">
                        <div class="body">
                            <div class="pricing-option">
                                <i class="icon-printer"></i>
                                <h5>Print design</h5>
                                <hr>
                                <div class="m-t-30 m-b-30">
                                    <h6>Files</h6>
                                    <p>.PSD .AI .PNG .SVG<br>Licensed for 1 project</p>
                                </div>
                                <div class="price">
                                    <span class="price">349 <b>$</b></span>
                                </div>
                                <a href="javascript:void(0);" class="btn btn-outline-secondary">Purchase now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
export default {
    name:'PricingComponent',
    components: {
        BreadCrumb,
    }
}
</script>
<style>

</style>